import { BannerStyleTypes } from "lib/types/banners.types";
import { Megaphone, Warning, WarningCircle, X } from "phosphor-react";
import React from "react";

type Props = {};

type BannerType = {
    type: BannerStyleTypes;
    icon?: React.ReactNode;
    isDismissable?: boolean;
    onDismiss?: any;
    isDisabled?: boolean;
    bannerTitle?: string;
    content?: any;
};

const BannerStyles: {
    [key: string]: {
        containerStyle: string;
        icon: React.ReactNode;
    };
} = {
    [BannerStyleTypes.INFO]: {
        containerStyle: ` border-l-progress border-l-[3px] bg-mainHoliday rounded-r-[5px] p-4 md:px-5 md:py-4`,
        icon: <Megaphone size={18} />
    },
    [BannerStyleTypes.ALERT]: {
        containerStyle: `border-l-error border-l-[3px] bg-errorBg text-error rounded-r-[5px] p-4 md:px-5 md:py-4`,
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.RESTRICTED]: {
        containerStyle: `border-error bg-white border rounded-[5px] p-4 md:px-5 md:py-4`,
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.INFOALERT]: {
        containerStyle: `bg-primary rounded-[5px] p-2 md:px-3 md:py-2 mb-4`,
        icon: <WarningCircle size={18} />
    },
    [BannerStyleTypes.INFO_WARNING]: {
        containerStyle: `border-l-progress border-l-[3px] bg-mainHoliday rounded-r-[5px] p-4`,
        icon: <Warning weight="fill" size={18} className="text-progress" />
    }
};

const Banner = ({
    type = BannerStyleTypes.INFO,
    icon,
    isDismissable = false,
    onDismiss,
    isDisabled = false,
    content,
    bannerTitle
}: BannerType) => {
    return (
        <div
            className={`w-full flex items-start   
        ${BannerStyles[type].containerStyle}`}
            data-testid={`banner-${type}`}
        >
            <span
                className={`shrink-0 flex items-center ${
                    bannerTitle ? "pt-[3px]" : "pt-[2px]"
                } pr-2`}
            >
                {icon || BannerStyles[type].icon}
            </span>
            {content && (
                <div className="flex flex-col items-start justify-center min-h-[24px]">
                    {bannerTitle && <p className="font-bold">{bannerTitle}</p>}
                    <p
                        className="text-smallLH20 static-content-banner w-full"
                        dangerouslySetInnerHTML={{
                            __html: content
                        }}
                    />
                </div>
            )}
            {isDismissable && (
                <button
                    className="shrink-0 ml-auto flex w-6 h-6 items-center justify-center"
                    onClick={onDismiss}
                    disabled={isDisabled}
                >
                    <X size={18} />
                </button>
            )}
        </div>
    );
};

export default Banner;
