import { Copy } from "phosphor-react";
import React from "react";
import { Tooltip } from "react-tooltip";

import { UserProfile } from "api/user/profile";
import { OnboardingWidgetCompanyInfoCopied } from "analytics/onboarding/GettingStartedAnalytics";

enum contentTypes {
    IATA = "IATA",
    ADDRESS = "Mailing Address",
    PHONE = "Phone Number",
    EMAIL = "Commission Email"
}

export default function CompanyInfoCard({
    userProfile
}: {
    userProfile: UserProfile;
}) {
    const userAnalyticsBlock = {
        email: userProfile.email,
        first_name: userProfile.firstName,
        last_name: userProfile.lastName
    };

    const contentTypeContent = [
        {
            label: "IATA#",
            body: "33520476",
            copyContent: "33520476",
            contentType: contentTypes.IATA
        },
        {
            label: "MAILING ADDRESS",
            body: `Fora Travel, Inc.
            228 Park Ave South #53272 
            New York, NY 10003-1502`,
            copyContent: `Fora Travel, Inc.
228 Park Ave South #53272 
New York, NY 10003-1502`,
            contentType: contentTypes.ADDRESS
        },
        {
            label: "PHONE NUMBER",
            body: `+1 844-409-FORA (3672)`,
            copyContent: `+1 844-409-3672`,
            contentType: contentTypes.PHONE
        },
        {
            label: "TO GET PAID",
            body: (
                <>
                    Book through Portal or send confirmations to{" "}
                    <a
                        className="underline font-bold"
                        href="mailto:commissions@fora.travel"
                        target="_blank"
                        rel="noreferrer"
                    >
                        commissions@fora.travel
                    </a>{" "}
                    for bookings made outside of Portal
                </>
            ),
            copyContent: `commissions@fora.travel`,
            contentType: contentTypes.EMAIL
        }
    ];

    // TODO: update with new copybutton component 12/11/2023
    const CopyButton = ({
        contentToCopy,
        contentType
    }: {
        contentToCopy: string;
        contentType: string;
    }): JSX.Element => {
        return (
            <>
                <Tooltip
                    content="Copied!"
                    openOnClick={true}
                    className="opaque-tooltip bg-black text-white "
                    id={contentType}
                    delayHide={1500}
                />
                <button
                    className="w-6 h-6 md:w-8 md:h-8 shrink-0 flex items-center justify-center rounded text-secondary cursor-pointer"
                    data-tooltip-id={contentType}
                    onClick={() => {
                        navigator.clipboard.writeText(contentToCopy || "");
                        OnboardingWidgetCompanyInfoCopied({
                            ...userAnalyticsBlock,
                            contentType: contentType
                        });
                    }}
                >
                    <Copy size={24} />
                </button>
            </>
        );
    };

    return (
        <div className="[@media(min-width:1160px)]:row-start-2 flex flex-col [@media(min-width:1160px)]:col-start-2">
            <div className="flex-1 bg-white rounded border border-stroke shadow-card p-6 md:p-8">
                <div className="grid gap-[9px]">
                    <h2 className="text-header font-bold">
                        Company information
                    </h2>
                    <div className="grid gap-[16px] grid-cols-[1fr] sm:grid-cols-[1fr_1fr] sm:gap-[16px] lg:grid-cols-[130px_minmax(180px,_1fr)_1fr_1fr] lg:gap-[16px]">
                        {contentTypeContent.map(contentBlock => (
                            <div key={contentBlock.label}>
                                <span className="flex justify-start items-center pb-1">
                                    <p className="text-secondary font-bold tracking-wide text-small mr-1">
                                        {contentBlock.label}
                                    </p>
                                    <CopyButton
                                        contentToCopy={contentBlock.copyContent}
                                        contentType={contentBlock.contentType}
                                    />
                                </span>
                                <p className="text-main whitespace-pre-line">
                                    {contentBlock.body}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
