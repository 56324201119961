import { URI_FORA_EMAIL } from "constants/urls";
import { HandWaving, NavigationArrow, PuzzlePiece } from "phosphor-react";
import InfoCardContainer from "components/common/InfoCardContainer";
import Link from "next/link";
import uiConfig from "ui-static-data.json";
import { CALENDLY_CONTAINER_ID } from "./ScheduleOnboardingSession";
import { OnboardingRecommendedNextStepsClicked } from "analytics/onboarding/GettingStartedAnalytics";
import { useAuth } from "hooks/use-auth";

const InlineLinkWithTracking = ({
    href,
    text
}: {
    href: string;
    text: string;
}) => {
    return (
        <Link
            className="text-link hover:text-blue-600"
            href={href}
            onClick={() =>
                OnboardingRecommendedNextStepsClicked({
                    linkTitle: text,
                    linkDestination: href
                })
            }
        >
            {text}
        </Link>
    );
};

export default function RecommendedNextSteps({
    showLinkToCalendly
}: {
    showLinkToCalendly: boolean;
}) {
    const { getUserGoogleLink } = useAuth();

    const scrollToCalendly = () =>
        document
            ?.querySelector("#" + CALENDLY_CONTAINER_ID)
            ?.scrollIntoView({ behavior: "smooth" });

    return (
        <dl className="space-y-4 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-4 md:space-y-0 mb-8">
            <InfoCardContainer
                iconType={PuzzlePiece}
                title="Learn the fundamentals"
            >
                <ul className="list-disc ml-4 md:ml-20 text-secondary">
                    <li>
                        <b className="text-black">Ready to book?</b> See “Jump
                        right in to book” below
                    </li>
                    <li>
                        <b className="text-black">Feeling lost?</b> Here are the
                        essential materials to get started:
                        <ul className="list-disc pl-8">
                            <li>
                                Watch{" "}
                                <InlineLinkWithTracking
                                    href="/training/getting-started/getting-started"
                                    text='"Welcome to Fora! Getting Started"'
                                />{" "}
                                (38 mins.) to understand what to expect on your
                                Fora Advisor journey
                            </li>
                            <li>
                                Review{" "}
                                <InlineLinkWithTracking
                                    href="/training/getting-started/training-need-to-know"
                                    text='"Need to know"'
                                />{" "}
                                (3 mins.) and the{" "}
                                <InlineLinkWithTracking
                                    href="/help/articles/advisor-glossary"
                                    text="glossary"
                                />{" "}
                                of common acronyms
                            </li>
                            <li>
                                Follow our guided{" "}
                                <InlineLinkWithTracking
                                    href="/training"
                                    text="Certified training"
                                />{" "}
                                to build your travel advisor knowledge and
                                entrepreneurial skills
                            </li>
                        </ul>
                    </li>
                </ul>
            </InfoCardContainer>
            <InfoCardContainer
                iconType={HandWaving}
                title="Engage with Fora’s community"
            >
                <ul className="list-disc ml-4 md:ml-20 text-secondary">
                    {showLinkToCalendly && (
                        <li>
                            Schedule your{" "}
                            <span
                                className="text-link hover:text-blue-600 cursor-pointer"
                                onClick={() => {
                                    OnboardingRecommendedNextStepsClicked({
                                        linkTitle: "Advisor Kickoff call!",
                                        linkDestination: "/"
                                    });
                                    scrollToCalendly();
                                }}
                            >
                                Advisor Kickoff call!
                            </span>
                        </li>
                    )}
                    <li>
                        After your Advisor Kickoff:
                        <ul className="list-disc pl-8">
                            <li>
                                Only have time for one more live event? Sign up
                                for{" "}
                                <InlineLinkWithTracking
                                    href={uiConfig.home.certifiedOfficeHours}
                                    text="Certified Office Hours"
                                />{" "}
                                (45 mins.) to ask our HQ team questions
                            </li>
                            <li>
                                Have a bit more time? Start joining{" "}
                                <InlineLinkWithTracking
                                    href={uiConfig.home.certifiedLabs}
                                    text="Certified Labs"
                                />{" "}
                                (45 mins.) to develop your skills
                            </li>
                        </ul>
                    </li>
                    <li>
                        Introduce yourself in{" "}
                        <InlineLinkWithTracking
                            href={uiConfig.home.introduceYourselfForum}
                            text="Forum"
                        />
                        , your community platform and get help from other new
                        Advisors in the{" "}
                        <InlineLinkWithTracking
                            href={uiConfig.home.forumChat}
                            text="Chat"
                        />
                    </li>
                    <li>
                        Access your{" "}
                        <InlineLinkWithTracking
                            href={URI_FORA_EMAIL}
                            text="Fora Email"
                        />{" "}
                        on the left and watch this{" "}
                        <InlineLinkWithTracking
                            href="/help/articles/how-do-i-customize-my-email-signature"
                            text="tutorial"
                        />{" "}
                        to set up your email signature
                    </li>
                </ul>
            </InfoCardContainer>
            <InfoCardContainer
                iconType={NavigationArrow}
                title="Jump right in to book"
            >
                <ul className="list-disc ml-4 md:ml-20 text-secondary">
                    <li>
                        Add your first{" "}
                        <InlineLinkWithTracking href="/clients" text="client" />{" "}
                        (even add yourself!)
                    </li>
                    <li>
                        Explore preferred partners and experiences to find the
                        right match
                    </li>
                    <li>
                        Learn how to make bookings with our video library{" "}
                        <InlineLinkWithTracking
                            href="/training/certified/certified-book-your-client"
                            text='"Book Your Client"'
                        />{" "}
                        (18 lessons to choose from), our step-by-step{" "}
                        <InlineLinkWithTracking
                            href={getUserGoogleLink(
                                uiConfig.home.makeYourFirstBooking
                            )}
                            text="guide"
                        />{" "}
                        (25 mins.), or join us at{" "}
                        <InlineLinkWithTracking
                            href={uiConfig.home.bookingBootcamp}
                            text="Booking Bootcamp"
                        />{" "}
                        (30 mins.) to work with someone on your first booking
                    </li>
                </ul>
            </InfoCardContainer>
        </dl>
    );
}
