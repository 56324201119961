import { AdvisorLevelDetails } from "components/AdvisorLevelDetails";
import { useAuth } from "hooks/use-auth";
const AdvisorLevel = () => {
    const { userProfile, isFeatureEnabled } = useAuth();
    if (!isFeatureEnabled("show_advisor_level")) {
        return null;
    }
    return (
        <div
            className={
                "flex flex-col  [@media(min-width:1160px)]:col-start-1 mb-2"
            }
        >
            <AdvisorLevelDetails
                showLearnMore={true}
                userProfile={userProfile}
            />
        </div>
    );
};
export default AdvisorLevel;
