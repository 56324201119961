import React, { useEffect, useState } from "react";
import {
    EventDateTimeSelected,
    ScheduledOnboardingWebinar
} from "analytics/onboarding/GettingStartedAnalytics";
import Loader from "components/common/Loader";
import CalendlyWidget from "components/CalendlyWidget";
import { URL_CALENDLY_SCHEDULING } from "constants/urls";
import { IOnboardingSessionStep } from "lib/types/onboarding.types";
import { IGettingStartedAnalytics } from "analytics/onboarding/GettingStartedAnalytics";
import { Transition } from "@headlessui/react";
import { CaretDown, CheckCircle } from "phosphor-react";
import { DateTime } from "luxon";

type ScheduleOnboardingSessionProps = {
    step: IOnboardingSessionStep | undefined;
    onUpdateOnboardingSession: (invitee_id: string, event_id: string) => void;
    userAnalyticsBlock: IGettingStartedAnalytics;
    personalEmail: string;
};

const CALENDLY_CUTOFF_WIDTH = 649;
export const CALENDLY_CONTAINER_ID = "calendly-container";

const ScheduleOnboardingSession = ({
    step,
    onUpdateOnboardingSession,
    userAnalyticsBlock,
    personalEmail
}: ScheduleOnboardingSessionProps) => {
    const confirmationPageUrl = step?.metadata?.invitee_id
        ? URL_CALENDLY_SCHEDULING + "/invitees/" + step.metadata.invitee_id
        : null;
    const isLoading = step === undefined;
    // We could use start_time to detect if call is scheduled, but we'll use invitee_id for backward compatibility with users who scheduled before new BE code was introduced.
    const callIsScheduled = Boolean(step?.metadata?.invitee_id);
    const startTime = step?.metadata?.start_time
        ? DateTime.fromISO(step.metadata.start_time)
        : null;
    const endTime = step?.metadata?.end_time
        ? DateTime.fromISO(step.metadata.end_time)
        : null;
    const [isOpen, setIsOpen] = useState(false);

    // Once the step data loads if the call is not scheduled, we should open the widget
    useEffect(() => {
        if (!isLoading && !callIsScheduled) {
            setIsOpen(true);
        }
    }, [isLoading, callIsScheduled]);

    async function handleEventScheduled(e: any) {
        // check for existence of event in payload and change the button accordingly
        e.data.payload.event &&
            ScheduledOnboardingWebinar({
                ...userAnalyticsBlock,
                source: "calendly",
                invitation_id: e.data.payload.event.uri
            });

        // See for shape of data.payload: https://www.npmjs.com/package/react-calendly#how-can-i-access-the-event-details-when-an-event-is-scheduled
        const invitee_id = extractLastPathFromUrl(e.data.payload.invitee.uri);
        const event_id = extractLastPathFromUrl(e.data.payload.event.uri);
        onUpdateOnboardingSession(invitee_id, event_id);
    }

    function handleDateTimeSelected() {
        EventDateTimeSelected(userAnalyticsBlock);
    }

    function extractLastPathFromUrl(url: string) {
        const urlSplit = url.split("/");
        return urlSplit[urlSplit.length - 1];
    }

    return (
        <div
            id={CALENDLY_CONTAINER_ID}
            className="bg-white rounded border border-stroke shadow-card p-4 lg:p-8 mb-8"
        >
            {isLoading ? (
                <>
                    <h2 className="text-staticMobileTitle lg:text-header font-bold mt-0 md:mt-[6px] custom-m-6 ">
                        Schedule an Advisor kickoff call
                    </h2>
                    <Loader className="my-4" />
                </>
            ) : (
                <>
                    <div>
                        <div className="flex items-center gap-2">
                            <h2 className="flex-grow text-staticMobileTitle lg:text-header font-bold">
                                {callIsScheduled ? (
                                    <>
                                        <CheckCircle
                                            weight="fill"
                                            className="text-success inline text-[20px] mb-1 lg:text-[28px]"
                                        />{" "}
                                        You are scheduled for an Advisor kickoff
                                        call
                                    </>
                                ) : (
                                    <>Schedule an Advisor kickoff call</>
                                )}
                            </h2>
                            <CaretDown
                                size={24}
                                weight="bold"
                                className={`${
                                    isOpen && "rotate-180 "
                                } text-label cursor-pointer`}
                                onClick={() => setIsOpen(!isOpen)}
                            />
                        </div>
                        {!callIsScheduled ? (
                            <p className="text-secondary mt-2">
                                Highly recommended 45-minute session. Connect
                                with other new advisors and learn the essentials
                                to getting started.
                            </p>
                        ) : startTime && endTime ? (
                            <p className="text-secondary mt-2">
                                {startTime
                                    .toFormat("h:mma")
                                    .toLocaleLowerCase()}{" "}
                                -{" "}
                                {endTime.toFormat("h:mma").toLocaleLowerCase()},{" "}
                                {startTime.toFormat("cccc, LLLL d, yyyy")}
                            </p>
                        ) : null}
                    </div>
                    <Transition
                        show={isOpen}
                        appear={true}
                        unmount={false}
                        enter="transform transition duration-[400ms] origin-top"
                        enterFrom="scale-y-0"
                        enterTo="scale-y-100"
                        leave="transform transition duration-[400ms] origin-top"
                        leaveFrom="scale-y-100 "
                        leaveTo="scale-y-0"
                    >
                        <div className={`mt-8 mx-auto max-w-[800px]`}>
                            <CalendlyWidget
                                url={
                                    confirmationPageUrl ||
                                    URL_CALENDLY_SCHEDULING
                                }
                                name={
                                    userAnalyticsBlock
                                        ? `${userAnalyticsBlock.first_name} ${userAnalyticsBlock.last_name}`
                                        : undefined
                                }
                                email={personalEmail}
                                styles={{
                                    height: "600px",
                                    minHeight: "600px",
                                    maxWidth: CALENDLY_CUTOFF_WIDTH,
                                    margin: "0 auto"
                                }}
                                pageSettings={{
                                    hideEventTypeDetails: true
                                }}
                                onEventScheduled={handleEventScheduled}
                                onDateAndTimeSelected={handleDateTimeSelected}
                            />
                        </div>
                    </Transition>
                </>
            )}
        </div>
    );
};

export async function getServerSideProps(context: any) {
    return {
        props: {}
    };
}

export default ScheduleOnboardingSession;
