import { IconProps } from "phosphor-react";
import { ReactNode } from "react";

export default function InfoCardContainer({
    iconType,
    title,
    headerText,
    children,
    primaryCTA
}: {
    iconType: React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
    >; // iconType is a Phosphor icon class (e.g. Buildings, ChalkboardTeacher, Money, UsersThree)
    title: string;
    headerText?: string;
    children?: ReactNode;
    primaryCTA?: ReactNode;
}) {
    const Icon = iconType;

    return (
        <div className="p-4 lg:p-8 bg-white border border-stroke rounded">
            <dt className="flex items-center">
                <div className="flex h-12 min-w-[48px] lg:h-10 lg:min-w-[40px] items-center justify-center rounded-[2px] bg-primary text-main">
                    <Icon className="text-[20px] lg:text-[24px]" />
                </div>
                <p className="ml-2 lg:ml-4 text-staticMobileTitle tracking-tight lg:tracking-normal lg:text-header font-extrabold text-main">
                    {title}
                </p>
            </dt>
            <dd className="mt-2 text-medium text-secondary">{headerText}</dd>
            {children ? children : null}
            {primaryCTA && <div className="mt-4">{primaryCTA}</div>}
        </div>
    );
}
