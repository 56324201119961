import React, { useEffect, useState } from "react";
import Link from "next/link";

import Dropdown from "components/common/Dropdown";
import { classNames, formatCurrency } from "lib/utils/index";
import { getData } from "utils/http-requests";
import { URI_COMMISSIONS, URL_DASHBOARD_BOOKINGS } from "constants/urls";
import { useAuth } from "hooks/use-auth";
import useWindowDimensions from "hooks/useWindowDimensions";
import {
    OnboardingWidgetBookingsCardFilterApplied,
    OnboardingWidgetBookingViewBookingsClicked
} from "analytics/onboarding/GettingStartedAnalytics";
import { datadogLogs } from "@datadog/browser-logs";

const bookingsFilter = {
    all_time: "?",
    YTD: `?period=ytd`,
    this_month: `?period=this_month`,
    last_month: `?period=last_month`,
    last_week: `?period=last_week`,
    this_week: `?period=this_week`,
    last_year: "?period=last_year"
};

const options = [
    {
        id: 1,
        link: bookingsFilter.all_time,
        value: "All time",
        query: ""
    },
    {
        id: 2,
        link: bookingsFilter.last_week,
        value: "Last week",
        query: "last_week"
    },
    {
        id: 3,
        link: bookingsFilter.last_month,
        value: "Last month",
        query: "last_month"
    },
    {
        id: 4,
        link: bookingsFilter.last_year,
        value: "Last year",
        query: "last_year"
    },
    {
        id: 5,
        link: bookingsFilter.this_week,
        value: "Current week",
        query: "this_week"
    },
    {
        id: 6,
        link: bookingsFilter.this_month,
        value: "Current month",
        query: "this_month"
    },
    {
        id: 7,
        link: bookingsFilter.YTD,
        value: "Current year",
        query: "ytd"
    }
];

export type TypeBookingSortingOptionsDashboard = {
    id: number;
    link: string;
    query: string;
    value: string;
};

type Props = {
    commissions: number;
    commission: number;
    commissionable_value: number;
    paid: number;
    commissions_paid: number;
    unpaid: number;
    future: number;
    commissions_unpaid: number;
};

const BookingsCard = ({}: {}) => {
    const defaultOption =
        options.find(item => item.link === bookingsFilter.YTD) || options[0];
    const { userProfile, token } = useAuth();
    const userAnalyticsBlock = {
        email: userProfile.email,
        first_name: userProfile.firstName,
        last_name: userProfile.lastName
    };

    const [isShowing, setIsShowing] = useState(false);
    const { width } = useWindowDimensions();
    const [bookingsData, setBookingsData] = useState<Props>();
    const [selectedOption, setSelectedOption] =
        useState<TypeBookingSortingOptionsDashboard>(defaultOption);
    const dataRangeUriQuery = selectedOption.query?.length
        ? `&dashboardDataRange=${selectedOption.query}`
        : "";
    const uriQuery = `${URI_COMMISSIONS}?bs=booked${dataRangeUriQuery}`;

    const getDashboardData = async (filterOption: string) => {
        try {
            const response = await getData(
                `${URL_DASHBOARD_BOOKINGS}${filterOption}`,
                token
            );

            setBookingsData(response);
        } catch (e) {
            datadogLogs.logger.error(
                `Dashboard: Booking Widget failed to fetch bookings data`,
                {
                    error: e
                }
            );
            console.error(e);
        }
    };

    useEffect(() => {
        getDashboardData(bookingsFilter.YTD);
    }, []);

    const handleSelectedDateOption = (item: any) => {
        setSelectedOption(item);
        setIsShowing(!isShowing);
        getDashboardData(item.link);

        OnboardingWidgetBookingsCardFilterApplied({
            ...userAnalyticsBlock,
            filterApplied: item.value
        });
    };

    return (
        <div
            data-testid="bookings_wrapper"
            className={
                "flex flex-col  [@media(min-width:1160px)]:col-start-1 mb-8"
            }
        >
            <div className="flex-1 bg-white rounded border border-stroke shadow-card py-4 px-8">
                <div className="flex flex-row justify-between md:flex-row w-full mb-4 pb-[6px]">
                    <h2 className="text-header font-bold mt-0 md:mt-[6px] custom-m-6 ">
                        Bookings
                    </h2>
                    <div className="md:ml-auto mt-0 shrink-0 flex items-center">
                        <p className="hidden md:block text-smallLH20 text-secondary">
                            Your bookings
                        </p>

                        <Dropdown
                            label={selectedOption.value}
                            isShowing={isShowing}
                            setIsShowing={setIsShowing}
                            menuPosClassName={"left-auto right-0"}
                            menuClassName="!py-2 !w-[216px] !mt-1 !shadow-button !text-main"
                            buttonClassName={
                                "w-full py-3 text-smallLH20 text-main font-bold border-1 md:border-0 !pl-3 md:!pl-2 !pr-2"
                            }
                            showFooter={false}
                        >
                            {options.map((item, index) => (
                                <div
                                    key={item.id}
                                    id={item.id?.toString()}
                                    className={classNames(
                                        "block py-2 text-medium font-normal text-main px-4 mb-2 last:mb-0 cursor-pointer hover:bg-successLight hover:font-bold",
                                        selectedOption?.id === item.id
                                            ? "bg-primaryBg !text-main"
                                            : ""
                                    )}
                                    onClick={() =>
                                        handleSelectedDateOption(item)
                                    }
                                >
                                    {item.value === "Year-to-date"
                                        ? "Year-to-date (Default)"
                                        : item.value}
                                </div>
                            ))}
                        </Dropdown>

                        <p className="hidden md:block text-smallLH20 text-secondary">
                            excluding canceled
                        </p>
                    </div>
                </div>
                {/* <div className="grid min1306:grid-cols-[1fr_1fr] gap-8 md:gap-4 min1306:gap-0 mb-4"> */}
                <div className="flex flex-col flex-wrap sm:flex-row flex-start min1306:flex-nowrap items-start min1306:flex-row gap-8 sm:gap-6 md:gap-4 min1306:gap-0 mb-4 min1306:items-start">
                    <div className="grid grid-rows-[1fr_1fr] xs:grid-rows-[1fr_1fr] sm:grid-rows-[1fr] min1306:grid-cols-[123px_1fr] flex-1 min1306:flex-none gap-4 md:gap-2 min1306:gap-0 min1306:pb-0">
                        <div className="flex flex-col justify-between  min1306:border-r min1306:border-stroke md:mb-6 min1306:mb-0 min1306:pr-8">
                            <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-3 md:mb-3">
                                Bookings
                            </h3>
                            <p
                                className="text-header  font-bold"
                                data-testid="bookings"
                            >
                                {bookingsData?.commissions || "0"}
                            </p>
                        </div>
                        <div className="max-w-[300px] md:justify-start min1306:border-r min1306:border-stroke pr-4 md:pr-2 min1306:px-6 md:mb-0 min1306:mb-0 ">
                            <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-3">
                                Total commissionable value
                            </h3>
                            <p
                                className="md:text-header text-headerF24_32 font-bold"
                                data-testid="commissionable_value"
                            >
                                {(typeof bookingsData?.commissionable_value ===
                                    "number" &&
                                    bookingsData.commissionable_value &&
                                    formatCurrency(
                                        bookingsData?.commissionable_value,
                                        0
                                    )) ||
                                    "$ –"}
                            </p>
                        </div>
                    </div>
                    <div className="grid sm:grid-rows-[1fr]  min1306:flex flex-1 min1306:flex-auto gap-4 md:gap-2 min1306:gap-0 min1306:pb-0">
                        <div className="mb-0">
                            <div className="max-w-[340px] min1306:border-r min1306:border-stroke pr-4 md:pr-2 min1306:pr-4 md:mb-6 min1306:mb-0 min1306:px-6">
                                <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-3 md:mb-3">
                                    Your total commission
                                </h3>
                                <p
                                    className="md:text-header text-headerF24_32 font-bold"
                                    data-testid="earned_commissions"
                                >
                                    {(typeof bookingsData?.commission ===
                                        "number" &&
                                        bookingsData.commission &&
                                        formatCurrency(
                                            bookingsData?.commission,
                                            0
                                        )) ||
                                        "$ –"}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row xs:flex-row sm:flex-wrap justify-start gap-6 sm:gap-8 md:gap-4 min1306:gap-8 min1306:pl-6">
                                <div>
                                    <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-2">
                                        Paid
                                    </h3>
                                    <p
                                        className="text-header font-bold "
                                        data-testid="paid"
                                    >
                                        {(typeof bookingsData?.paid ===
                                            "number" &&
                                            bookingsData.paid &&
                                            formatCurrency(
                                                bookingsData?.paid,
                                                0
                                            )) ||
                                            "$ –"}
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-2">
                                        pending
                                    </h3>
                                    <p
                                        className="text-header font-bold"
                                        data-testid="payment_pending"
                                    >
                                        {(typeof bookingsData?.unpaid ===
                                            "number" &&
                                            bookingsData.unpaid &&
                                            formatCurrency(
                                                bookingsData?.unpaid,
                                                0
                                            )) ||
                                            "$ –"}
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-small font-bold text-secondary uppercase tracking-wide mb-2">
                                        future
                                    </h3>
                                    <p
                                        className="text-header font-bold "
                                        data-testid="future_commissionable_value"
                                    >
                                        {(typeof bookingsData?.future ===
                                            "number" &&
                                            bookingsData.future &&
                                            formatCurrency(
                                                bookingsData?.future,
                                                0
                                            )) ||
                                            "$ –"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="text-medium font-bold text-main/40 md:hidden">
                        Excludes canceled bookings
                    </p>
                    <Link
                        href={uriQuery}
                        onClick={() =>
                            OnboardingWidgetBookingViewBookingsClicked(
                                userAnalyticsBlock
                            )
                        }
                        data-testid="see_details_dashboard_bookings"
                        className="text-medium md:text-smallLH20 font-bold text-link hover:text-blue-600"
                    >
                        View these bookings
                    </Link>
                </div>
            </div>
        </div>
    );
};

export async function getServerSideProps(context: any) {
    return {
        props: {}
    };
}
export default BookingsCard;
