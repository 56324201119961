import Layout, { LayoutProps } from "components/Layout";
import SystemBanners from "components/SystemBanners";

export interface HeaderBannerLayoutProps extends LayoutProps {
    headerText?: string; // If header is not passed in, we'll use the title. (This is the most common use case.)
}

export default function HeaderBannerLayout({
    headerText,
    ...props
}: HeaderBannerLayoutProps) {
    const { children, title } = props;
    return (
        <Layout {...props}>
            <div className="container-1440">
                <SystemBanners />
                <h1 className="text-titleLH36 lg:text-jumbo font-extrabold text-main mb-4 md:mb-6">
                    {headerText ?? title}
                </h1>
                {children}
            </div>
        </Layout>
    );
}
